import { Group, Text } from '@mantine/core';
import { useApi } from 'api/api-context';
import DeviceTypeIcon from 'components/device/DeviceTypeIcon';
import { forwardRef, useCallback, useMemo } from 'react';
import {
  DataMultiSelect,
  DataMultiSelectInheritedProps,
  DataSelect,
  DataSelectInheritedProps,
} from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';

/**
 * Fetches device types from API.
 */
function useDeviceTypeSelect({
  parentDeviceTypeId,
  departmentId,
  listSubtypes = false,
  activeOnly = false,
}: { parentDeviceTypeId?: number; departmentId?: number; listSubtypes?: boolean; activeOnly?: boolean } = {}) {
  const { getAction } = useApi();

  const fetchAllDeviceTypes = useCallback(async () => {
    const action = getAction('DeviceListTypes');

    const deviceTypes = await action({
      query: {
        filters: {
          'parentDeviceTypeId.eq': parentDeviceTypeId,
          'listSubtypes.eq': (parentDeviceTypeId && parentDeviceTypeId !== 0) || listSubtypes ? 1 : 0,
        },
      },
    });

    return deviceTypes.map((deviceType) => ({
      label: deviceType.deviceTypeName,
      value: String(deviceType.deviceTypeId),
      image: listSubtypes ? '' : String(deviceType.deviceTypeId),
      group: listSubtypes ? deviceType.parentDeviceTypeName ?? 'Nezaradené' : '',
    }));
  }, [getAction, parentDeviceTypeId, listSubtypes]);

  const fetchActiveDeviceTypes = useCallback(async () => {
    const action = getAction('DeviceActiveTypes');

    const deviceTypes = await action({
      parameters: {
        departmentId: String(departmentId),
      },
    });

    return deviceTypes.map((deviceType) => ({
      label: deviceType.deviceTypeName,
      value: String(deviceType.deviceTypeId),
      image: String(deviceType.deviceTypeId),
      group: '',
    }));
  }, [getAction, departmentId]);

  return activeOnly ? { fetchDeviceTypes: fetchActiveDeviceTypes } : { fetchDeviceTypes: fetchAllDeviceTypes };
}

type DeviceTypeSelectProps = DataSelectInheritedProps & {
  parentDeviceTypeId?: number;
  listSubtypes?: boolean;
  departmentId?: number;
  activeOnly?: boolean;
};

type DeviceTypeMultiSelectProps = DataMultiSelectInheritedProps & {
  parentDeviceTypeId?: number;
  listSubtypes?: boolean;
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
}

const DeviceTypeItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, ...others }: ItemProps, ref) => {
  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        {/* NOTE: image is a workaround to get the ID of the device type */}
        {image && <DeviceTypeIcon typeId={Number(image)} />}
        <Text size="sm">{label}</Text>
      </Group>
    </div>
  );
});

/**
 * Select for device types.
 */
export function DeviceTypeSelect({
  parentDeviceTypeId,
  listSubtypes,
  departmentId,
  activeOnly,
  value,
  ...props
}: DeviceTypeSelectProps) {
  const key = useMemo(() => nanoid(), [parentDeviceTypeId, listSubtypes, departmentId, activeOnly]);
  const { fetchDeviceTypes } = useDeviceTypeSelect({ parentDeviceTypeId, listSubtypes, departmentId, activeOnly });

  return (
    <DataSelect
      key={key}
      value={value}
      valueProp="value"
      labelProp="label"
      imageProp="image"
      groupProp="group"
      action={fetchDeviceTypes}
      itemComponent={listSubtypes ? undefined : DeviceTypeItem}
      icon={value && !isNaN(Number(value)) ? <DeviceTypeIcon typeId={Number(value)} /> : undefined}
      {...props}
    />
  );
}

/**
 * Multi select for device types
 */
export function DeviceTypeMultiSelect({ parentDeviceTypeId, listSubtypes, ...props }: DeviceTypeMultiSelectProps) {
  const key = useMemo(() => nanoid(), [parentDeviceTypeId, listSubtypes]);
  const { fetchDeviceTypes } = useDeviceTypeSelect({ parentDeviceTypeId, listSubtypes });

  return (
    <DataMultiSelect
      key={key}
      valueProp="value"
      labelProp="label"
      imageProp="image"
      groupProp="group"
      action={fetchDeviceTypes}
      itemComponent={listSubtypes ? undefined : DeviceTypeItem}
      {...props}
    />
  );
}
