import { Button, createStyles, Group, Modal, NumberInput, rem, Stack, TextInput } from '@mantine/core';
import {
  CopyableFieldCheckbox,
  HiddenFieldCheckbox,
  ReadOnlyFieldCheckbox,
  RequiredFieldCheckbox,
} from 'pages/revisions-module/template-editor/editors/form/configurator/InputCheckboxes';
import { useCallback, useEffect } from 'react';
import { IFormInputSpec, IFormInputType } from 'pages/revisions-module/template-editor/editors/form/types';
import { useForm } from '@mantine/form';
import InputNameDisplay from 'pages/revisions-module/template-editor/editors/form/configurator/InputNameDisplay';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import InputTypeSelect from 'pages/revisions-module/template-editor/editors/form/configurator/InputTypeSelect';
import InputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/InputPropertiesSubForm';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import P4Regular from 'components/typography/P4Regular';

const useStyles = createStyles((theme) => ({
  wrapper: {
    borderTop: `${rem(1)} solid ${theme.colors.gray[1]}`,
  },
  section: {
    borderBottom: `${rem(1)} solid ${theme.colors.gray[1]}`,
  },
}));

/**
 * Parameters of the InputConfigurator component.
 */
export interface InputConfiguratorProps {
  opened: boolean;
  primaryButtonLabel?: string;
  readOnly?: Partial<Record<keyof IFormInputSpec, boolean>>;
  hidden?: Partial<Record<keyof IFormInputSpec, boolean>>;
  initialValues?: IFormInputSpec;
  inputTypes?: Partial<Record<IFormInputType, boolean>>;
  onClose: () => void;
  onSubmit: (spec: IFormInputSpec) => void;
}

/**
 * Used to create and edit inputs in a form.
 */
export default function InputConfigurator({
  opened,
  primaryButtonLabel = 'Uložiť',
  readOnly = {},
  hidden = {},
  initialValues = {
    label: '',
    name: '',
    required: false,
    copyable: true,
    readOnly: false,
    hidden: false,
    type: 'text',
  },
  inputTypes,
  onClose,
  onSubmit,
}: InputConfiguratorProps) {
  const { classes } = useStyles();
  const { generateInputName } = useFormInputs();

  const form = useForm<IFormInputSpec>({
    initialValues,
    validate: {
      label: createValidator([required]),
    },
  });

  const submit = useCallback(
    (spec: IFormInputSpec) => {
      onSubmit(spec);
      onClose();
    },
    [onSubmit, onClose]
  );

  useEffect(() => {
    if (opened) {
      form.reset();
      form.setValues(initialValues);
    }
  }, [opened]);

  useEffect(() => {
    if (!readOnly.name) {
      form.setFieldValue('name', generateInputName(form.values.label));
    }
  }, [form.values.label, readOnly.name]);

  const isDirty = form.isDirty();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Konfigurátor poľa"
      centered
      closeOnClickOutside={!isDirty}
      closeOnEscape={!isDirty}
    >
      <form onSubmit={form.onSubmit(submit)}>
        <Stack spacing={16} pt={16} className={classes.wrapper}>
          <Stack spacing={0}>
            <Stack spacing={12} pb={16} className={classes.section}>
              <TextInput label="Názov poľa" size="lg" data-autofocus required {...form.getInputProps('label')} />
              <InputNameDisplay name={form.values.name} />
              <Group noWrap spacing={12} sx={{ ':empty': { display: 'none' } }}>
                {!hidden.minWidth && (
                  <NumberInput
                    size="sm"
                    label="Minimálna šírka"
                    rightSection={<P4Regular>px</P4Regular>}
                    {...form.getInputProps('minWidth')}
                  />
                )}
                {!hidden.maxWidth && (
                  <NumberInput
                    size="sm"
                    label="Maximálna šírka"
                    rightSection={<P4Regular>px</P4Regular>}
                    {...form.getInputProps('maxWidth')}
                  />
                )}
              </Group>
            </Stack>
            <Stack spacing={8} py={16} className={classes.section}>
              <InputTypeSelect readOnly={readOnly.type} inputTypes={inputTypes} {...form.getInputProps('type')} />
              <Stack spacing={12} bg="blue.0" p={12} sx={{ borderRadius: rem(4), ':empty': { display: 'none' } }}>
                <InputPropertiesSubForm type={form.values.type} form={form} />
              </Stack>
            </Stack>
            <Stack spacing={12} py={16} className={classes.section}>
              {!hidden.required && <RequiredFieldCheckbox {...form.getInputProps('required')} />}
              {!hidden.copyable && <CopyableFieldCheckbox {...form.getInputProps('copyable')} />}
              {!hidden.readOnly && <ReadOnlyFieldCheckbox {...form.getInputProps('readOnly')} />}
              {!hidden.hidden && <HiddenFieldCheckbox {...form.getInputProps('hidden')} />}
            </Stack>
          </Stack>
          <Group position="apart">
            <Button type="button" variant="subtle-gray" size="md" onClick={onClose}>
              Zrušiť
            </Button>
            <Button type="submit" variant="primary" size="md">
              {primaryButtonLabel}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
