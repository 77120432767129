import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';
import DateInputCellEditor from 'components/forms/revision/fill-out/modals/device-measurement-modal/ag-grid/columns/DateInputCellEditor';
import SelectCellEditor from 'components/forms/revision/fill-out/modals/device-measurement-modal/ag-grid/columns/SelectCellEditor';
import { ColDef } from 'ag-grid-community';

interface ColAdapterProps {
  spec: IFormInputSpec;
  index: number;
}

/**
 * Based on type, returns the correct column specification for the ag-grid.
 */
export default function defineColumn({ spec, index }: ColAdapterProps): ColDef {
  const baseProps: ColDef = {
    field: spec.name,
    headerName: spec.required ? `${spec.label} (povinné)` : spec.label,
    minWidth: spec.minWidth,
    maxWidth: spec.maxWidth,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    editable: true,
    wrapHeaderText: true,
  };

  switch (spec.type) {
    case 'text':
      return {
        ...baseProps,
        cellDataType: 'text',
      };

    case 'number':
      return {
        ...baseProps,
        cellDataType: 'number',
      };

    case 'date':
      return {
        ...baseProps,
        cellDataType: 'date',
        cellEditor: DateInputCellEditor,
        cellEditorParams: {
          columnName: spec.name,
        },
      };

    case 'checkbox':
      return {
        ...baseProps,
        cellDataType: 'boolean',
      };

    case 'select':
      return {
        ...baseProps,
        cellDataType: 'select',
        cellEditor: SelectCellEditor,
        cellEditorParams: {
          columnName: spec.name,
          options: spec.options,
        },
      };

    default:
      return {
        ...baseProps,
        cellDataType: 'text',
      };
  }
}
