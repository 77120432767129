import { InputProps, MantineThemeOverride, rem } from '@mantine/core';
import { P1Regular, P2Regular } from 'theme/typography';

export type InputTheme = NonNullable<MantineThemeOverride['components']>['Input'];

export const InputDefaultProps: Partial<InputProps> = {
  size: 'md',
  iconWidth: 16,
};

export const Input: InputTheme = {
  defaultProps: InputDefaultProps,
  sizes: {
    lg: (theme) => ({
      input: {
        paddingTop: rem(12),
        paddingBottom: rem(12),
        paddingRight: rem(16),
        paddingLeft: rem(16),
        height: rem(48),
        minHeight: rem(48),
        ...P1Regular,
        '&[data-with-icon=true]': {
          paddingLeft: rem(44),
        },
      },
      rightSection: {
        paddingLeft: rem(16),
        paddingRight: rem(16),
      },
      icon: {
        marginLeft: rem(16),
        width: rem(24),
        height: 'auto',
      },
    }),
    md: (theme) => ({
      input: {
        paddingTop: rem(8),
        paddingBottom: rem(8),
        paddingRight: rem(12),
        paddingLeft: rem(12),
        height: rem(40),
        minHeight: rem(40),
        ...P1Regular,
        '&[data-with-icon=true]': {
          paddingLeft: rem(40),
        },
      },
      rightSection: {
        paddingLeft: rem(12),
        paddingRight: rem(12),
      },
      icon: {
        marginLeft: rem(12),
        width: rem(20),
        height: 'auto',
      },
    }),
    sm: (theme) => ({
      input: {
        paddingTop: rem(4),
        paddingBottom: rem(4),
        paddingRight: rem(8),
        paddingLeft: rem(8),
        height: rem(32),
        minHeight: rem(32),
        ...P2Regular,
        '&[data-with-icon]': {
          paddingLeft: rem(28),
        },
      },
      icon: {
        marginLeft: rem(8),
        width: rem(16),
        height: 'auto',
      },
      rightSection: {
        paddingLeft: rem(8),
        paddingRight: rem(8),
      },
    }),
  },
  styles: (theme) => ({
    input: {
      backgroundColor: theme.white,
      '&:fill': {
        color: theme.colors.blue[8],
      },
      '&:focus': {
        borderColor: theme.colors.blue[8],
        boxShadow: '0px 0px 0px 4px #DBE4FF',
        color: theme.colors.gray[8],
      },
      '&[data-invalid=true]:focus': {
        borderColor: theme.colors.red[8],
        boxShadow: '0px 0px 0px 4px #FFE3E8',
      },
      '&:disabled': {
        color: theme.colors.gray[4],
        backgroundColor: theme.colors.gray[1],
      },
      '&[readonly]': {
        color: theme.colors.gray[6],
        backgroundColor: theme.colors.gray[1],
        cursor: 'not-allowed',
      },
    },
    rightSection: {
      width: 'fit-content',
    },
    //label disabled zmena farby
  }),
};
